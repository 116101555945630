import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompany, resetCompanyEditData} from './slice';
import Spinner from '../../../components/Spinner';
import apiClient from '../../../store/apiClient';
import CommonInput from '../../../components/CommonInput';

const CompanyPage = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {loading, data} = useAppSelector((state) => state.adminCompany);
    const [company, setCompany] = useState(data);

    useEffect(() => {
        dispatch(fetchCompany(+(id || 0)));

        return () => {
            dispatch(resetCompanyEditData());
        }
    }, []);

    useEffect(() => {
        setCompany(data);
    }, [data]);
    const handleInputChange = (name: string, value: String) => {
        setCompany({
            ...company,
            [name]: value,
        })
    };

    const handleFormSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        await apiClient.saveCompany(company);
        navigate('../');
    };

    return <>
        {loading && <Spinner/>}

        <form className="admin-form" onSubmit={handleFormSubmit}>
            <CommonInput label={t('company.name')} required={true} value={company.name} onChange={(e) => handleInputChange('name', e.target.value)}/>

            <CommonInput label={t('company.code')} required={false} value={company.code} onChange={(e) => handleInputChange('code', e.target.value)}/>

            <CommonInput label={t('company.address')} required={true} value={company.address} onChange={(e) => handleInputChange('address', e.target.value)}/>

            <CommonInput label={t('company.email')} type={'email'} required={true} value={company.email} onChange={(e) => handleInputChange('email', e.target.value)}/>

            <CommonInput label={t('company.bank.name')} required={true} value={company.bankName} onChange={(e) => handleInputChange('bankName', e.target.value)}/>

            <CommonInput label={t('company.bank.address')} required={true} value={company.bankAddress} onChange={(e) => handleInputChange('bankAddress', e.target.value)}/>

            <CommonInput label={t('company.bank.country')} required={true} value={company.bankCountry} onChange={(e) => handleInputChange('bankCountry', e.target.value)}/>

            <CommonInput label={t('company.accountHolder')} required={true} value={company.accountHolder} onChange={(e) => handleInputChange('accountHolder', e.target.value)}/>

            <CommonInput label={t('company.iban')} required={true} value={company.iban} onChange={(e) => handleInputChange('iban', e.target.value)}/>

            <CommonInput label={t('company.bic')} required={true} value={company.bic} onChange={(e) => handleInputChange('bic', e.target.value)}/>

            <CommonInput label={t('company.currency')} required={true} readOnly value={company.currency} onChange={(e) => handleInputChange('currency', e.target.value)}/>

            <div className="buttons-wrap">
                <button type="button" className="main-button size-normal cancel-button" onClick={() => navigate('../')}>{t('button.cancel')}</button>

                <button type="submit" className="main-button size-normal">{t('button.save')}</button>
            </div>
        </form>
    </>;
};

export default CompanyPage;