import {configureStore} from '@reduxjs/toolkit';
import appReducer from './appSlice';
import productReducer from '../pages/Product/slice';
import adminCompanyReducer from '../pages/AdminPage/CompanyPage/slice';
import adminCompaniesReducer from '../pages/AdminPage/CompaniesPage/slice';
import adminProductReducer from '../pages/AdminPage/ProductPage/slice';
import adminProductsReducer from '../pages/AdminPage/ProductsPage/slice';
import adminTransfersReducer from '../pages/AdminPage/TransfersPage/slice';
import adminTransactionsReducer from '../pages/AdminPage/TransactionsPage/slice';
import ordersReportReducer from '../pages/AdminPage/OrdersReportPage/slice';

const store = configureStore({
    reducer: {
        app: appReducer,
        product: productReducer,
        adminCompany: adminCompanyReducer,
        adminCompanies: adminCompaniesReducer,
        adminProduct: adminProductReducer,
        adminProducts: adminProductsReducer,
        adminTransfers: adminTransfersReducer,
        adminTransactions: adminTransactionsReducer,
        ordersReport: ordersReportReducer
    },
});

export default store;