import axios, {AxiosInstance} from 'axios';
import config from '../config';
import RPCError from '../models/RPCError';
import {initDownload} from '../services/imageService';
import {Auth0ContextInterface} from '@auth0/auth0-react/src/auth0-context';
import {setError} from '../redux/appSlice';

export class ApiClient {
    private http: AxiosInstance;
    private static auth0: undefined | Auth0ContextInterface;
    private static reduxDispatcher: any;

    constructor(url: string) {
        this.http = axios.create({
            baseURL: url,
        });

        // intercept all outgoing requests
        this.http.interceptors.request.use(ApiClient.beforeRequest);

        // intercept all incoming responses
        this.http.interceptors.response.use(ApiClient.onRequestSuccess, ApiClient.onRequestFailure);
    }

    public static setAuth0(auth0) {
        ApiClient.auth0 = auth0;
    }

    public static setReduxDispatcher(dispatcher) {
        ApiClient.reduxDispatcher = dispatcher;
    }

    private static async beforeRequest(request) {
        const token = await ApiClient.auth0?.getAccessTokenSilently();

        // add an auth header
        request.headers.Authorization = `Bearer ${token}`;

        return request;
    }

    private static onRequestSuccess(response) {
        if (response.data.error) {
            if (ApiClient.reduxDispatcher) {
                ApiClient.reduxDispatcher(setError(response.data.error.message));
            }

            throw new RPCError(response.data.error.message, response.data.error.code);
        }

        return response.data.result;
    }

    private static async onRequestFailure(err) {
        console.log(err);
        // check for the `401` status and force-logout, otherwise proceed with regular workflow
        if (err.response.status === 401) {
            console.log('GLOBAL LOGOUT!');
            await ApiClient.auth0?.logout({returnTo: window.location.origin});
        } else {
            console.log('fetch failed', err);
        }

        throw err;
    }

    private async jsonRpc(method, params: any[] = []): Promise<any> {
        return await this.http.post(
            '',
            {'jsonrpc': '2.0', 'id': 1, method, params},
        );
    }

    getProduct = async (id: number): Promise<any> => {
        try {
            return await this.jsonRpc('getProduct', [id]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    getProducts = async (): Promise<any> => {
        try {
            return await this.jsonRpc('getProducts');
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    registerMerchant = async (authId: string, name: string): Promise<any> => {
        try {
            return await this.jsonRpc('registerMerchant', [authId, name]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    previewMerchantUrlName = async (name: string): Promise<string> => {
        try {
            return await this.jsonRpc('previewMerchantUrlName', [name]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    getMerchant = async (): Promise<any> => {
        try {
            return await this.jsonRpc('getMerchant');
        } catch (e) {
            if (e instanceof RPCError && e.code === 404) {
                return null;
            } else {
                console.log('fetch failed', e);
                throw(e);
            }
        }
    };

    setMerchantLogo = async (
        logo: string,
        logoBody: string | null | ArrayBuffer = null,
    ): Promise<any> => {
        try {
            return await this.jsonRpc('setMerchantLogo', [
                logo,
                logoBody,
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    editProduct = async (
        id: number,
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: Flag,
        isPublished: Flag,
        showServiceFee: Flag,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: Flag,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string | null | ArrayBuffer = null,
        prices: string | null = null,
        redirectUrl: string | null = null,
        phoneNumber: string | null = null
    ): Promise<any> => {
        try {
            return await this.jsonRpc('editProduct', [
                id,
                type,
                name,
                description,
                totalPrice,
                basePrice,
                serviceFee,
                productPrice,
                taxValue,
                taxClass,
                deliveryUrl,
                isHidden,
                isPublished,
                showServiceFee,
                uiMajorColor,
                uiMinorColor,
                uiMajorTextColor,
                uiMinorTextColor,
                tpSuccessTitle,
                tpSuccessText,
                tpSuccessInstructionsText,
                tpSuccessInstructionsButton,
                tpShowShare,
                tpShowReceipt,
                tpShowInstructions,
                smsText,
                image,
                imageBody,
                prices,
                redirectUrl,
                phoneNumber
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    addProduct = async (
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: number,
        isPublished: number,
        showServiceFee: number,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: number,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string | null | ArrayBuffer = null,
        prices: string | null = null,
        redirectUrl: string | null = null,
        phoneNumber: string | null = null
    ): Promise<any> => {
        try {
            return await this.jsonRpc('addProduct', [
                type,
                name,
                description,
                totalPrice,
                basePrice,
                serviceFee,
                productPrice,
                taxValue,
                taxClass,
                deliveryUrl,
                isHidden,
                isPublished,
                showServiceFee,
                uiMajorColor,
                uiMinorColor,
                uiMajorTextColor,
                uiMinorTextColor,
                tpSuccessTitle,
                tpSuccessText,
                tpSuccessInstructionsText,
                tpSuccessInstructionsButton,
                tpShowShare,
                tpShowReceipt,
                tpShowInstructions,
                smsText,
                image,
                imageBody,
                prices,
                redirectUrl,
                phoneNumber
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    addProductCodes = async (id: number, codes: string[]) => {
        try {
            return await this.jsonRpc('addProductCodes', [id, codes]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    genProductCodes = async (id: number, quantity: number, length: number, type: 'alpha' | 'numeric' | 'alphanumeric'): Promise<any> => {
        try {
            return await this.jsonRpc('genProductCodes', [id, quantity, length, type]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    genQrCode = async (id: number): Promise<any> => {
        try {
            return await this.jsonRpc('genQrCode', [id]);
        } catch (e) {
            console.log('genQrCode failed', e);
            throw (e);
        }
    };

    getVoucherPdf = async (id: number, name: string): Promise<any> => {
        try {
            const response = await this.jsonRpc('getVoucherPdf', [id]);
            const tmp = await (await fetch(`data:application/pdf;base64,${response}`)).blob();

            initDownload(`voucher-${name}.pdf`, URL.createObjectURL(tmp));
        } catch (e) {
            console.log('getVoucherPdf failed', e);
            throw (e);
        }
    };

    getVouchersPdf = async (): Promise<any> => {
        try {
            const response = await this.jsonRpc('getVouchersPdf');
            const tmp = await (await fetch(`data:application/pdf;base64,${response}`)).blob();

            initDownload('vouchers.pdf', URL.createObjectURL(tmp));
        } catch (e) {
            console.log('getVouchersPdf failed', e);
            throw (e);
        }
    };

    genVouchers = async (
        nameBase: string,
        startFrom: number,
        quantity: number,
        colorScheme: ColorScheme,
        taxClass: number,
        prices: number[],
        defPrice: number,
        description: string,
        smsText: string,
    ): Promise<any> => {
        try {
            const response = await this.jsonRpc('genVouchers', [
                nameBase,
                startFrom,
                quantity,
                colorScheme,
                taxClass,
                prices,
                defPrice,
                description,
                smsText,
            ]);
            const tmp = await (await fetch(`data:application/pdf;base64,${response}`)).blob();

            initDownload('vouchers.pdf', URL.createObjectURL(tmp));
        } catch (e) {
            console.log('genVouchers failed', e);
            throw (e);
        }
    };

    getCompanies = async (): Promise<any> => {
        try {
            return await this.jsonRpc('getCompanies');
        } catch (e) {
            console.log('getCompanies failed', e);
            throw(e);
        }
    };

    getCompany = async (id: number): Promise<any> => {
        try {
            return await this.jsonRpc('getCompany', [id]);
        } catch (e) {
            console.log('getCompany failed', e);
            throw(e);
        }
    };

    saveCompany = async (company: ICompany): Promise<any> => {
        try {
            return await this.jsonRpc('saveCompany', [company]);
        } catch (e) {
            console.log('saveCompany failed', e);
            throw(e);
        }
    };

    saveProduct = async (product: IProduct): Promise<any> => {
        try {
            return await this.jsonRpc('saveProduct', [product]);
        } catch (e) {
            console.log('saveProduct failed', e);
            throw(e);
        }
    };

    getTransfers = async (): Promise<any> => {
        try {
            return await this.jsonRpc('getTransfers');
        } catch (e) {
            console.log('getTransfers failed', e);
            throw(e);
        }
    };

    getTransferFile = async (id: number, name: string): Promise<any> => {
        try {
            const response = await this.jsonRpc('getTransferFile', [id]);
            const tmp = await (await fetch(`data:application/xml;base64,${response}`)).blob();

            initDownload(`transfer-${name}.xml`, URL.createObjectURL(tmp));
        } catch (e) {
            console.log('getVoucherPdf failed', e);
            throw (e);
        }
    };

    getOrdersReport = async (id: number, from: string, to: string): Promise<any> => {
        try {
            const response = await this.jsonRpc('getOrdersReport', [id, from, to,]);
            const tmp = await (await fetch(`data:application/pdf;base64,${response}`)).blob();

            initDownload(`${from} - ${to}.pdf`, URL.createObjectURL(tmp));
        } catch (e) {
            console.log('saveProduct failed', e);
            throw(e);
        }
    };

    getTransactions = async(filters: {}): Promise<ITransaction[]> => {
        try {
            return  await this.jsonRpc('getTransactions', [filters]);
        } catch (e) {
            console.log('getTransactions failed', e);
            throw(e);
        }
    }
}

const apiClient = new ApiClient(config.api.url);

export default apiClient;
