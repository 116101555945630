import './CodesPage.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import UploadCodesComponent from '../../components/UploadCodesComponents/UploadCodesComponent';
import GenCodesComponent from '../../components/GenCodesComponents/GenCodesComponent';
import {useParams} from 'react-router-dom';

const CodesPage = () => {
    const {t} = useTranslation();

    let {id} = useParams();

    const [showedPage, setShowedPage] = useState<string>('upload');

    return (
        <section className="codes">
            <div style={{backgroundColor: '#e7e6e6'}}>
                <button className="tab-btn tab-btn-upload"
                        style={{backgroundColor: `${showedPage === 'upload' ? '#efefef' : '#e7e6e6'}`}}
                        onClick={() => {
                            setShowedPage('upload');
                            console.log(showedPage);
                        }}
                >{t('button.upload')}</button>
                <button
                    className="tab-btn tab-btn-gen"
                    style={{backgroundColor: `${showedPage === 'generate' ? '#efefef' : '#e7e6e6'}`}}
                    onClick={() => {
                        setShowedPage('generate');
                    }}
                >{t('button.generate')}
                </button>
            </div>

            {showedPage === 'upload' && <UploadCodesComponent id={+(id || 0)}/>}

            {showedPage === 'generate' && <GenCodesComponent id={+(id || 0)}/>}
        </section>
    );
};

export default CodesPage;