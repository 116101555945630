import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n.use(detector) // detect the language based on browser settings, checks previously selected language in cookies.
    .use(backend) // fetch translations from the server. By default it checks the /locales/{lang}/{namespace}.json files, but this can be redefined in configs
    .use(initReactI18next) // pass the i18next to react-i18next
    .init({
        fallbackLng: 'fi', // use fi if detected lng is not available
        load: 'languageOnly',
        debug: false, // set to `true` to see a lot of ugly console messages...
        saveMissing: false, // send not translated keys to endpoint
        initImmediate: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: false, // TODO: the use of `Suspense` allows to get read of flickering when the async data is fetched, however it conflicts with the `mobx`, so disable it for now...
        },
    });

export default i18n;
