import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompanies} from './slice';
import Spinner from '../../../components/Spinner';
import CommonInput from '../../../components/CommonInput';
import apiClient from '../../../store/apiClient';

const OrdersReportPage = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {loading, companies} = useAppSelector((state) => state.ordersReport);
    const [companyId, setCompanyId] = useState<number | undefined>();
    const [from, setFrom] = useState<string>(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [to, setTo] = useState<string>(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);

    const handleFormSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        await apiClient.getOrdersReport(companyId || 0, from, to);
    };

    return <>
        {loading && <Spinner/>}

        <form className="admin-form" onSubmit={handleFormSubmit}>
            <CommonInput
                required={true}
                type={'select'}
                label={t('report.paymentAccount')}
                value={companyId || ''}
                onChange={(e) => setCompanyId(+e.target.value || undefined)}
                options={[
                    {value: '', name: t('select.noCompany')},
                    ...companies.map((i) => ({value: i.id, name: i.name})),
                ]}
            />

            <CommonInput
                required={true}
                type={'date'}
                label={t('report.from')}
                value={from || ''}
                onChange={(e) => setFrom(e.target.value || undefined)}
            />

            <CommonInput
                required={true}
                type={'date'}
                label={t('report.to')}
                value={to || ''}
                onChange={(e) => setTo(e.target.value || undefined)}
            />

            <div className="buttons-wrap">
                <button type="submit" className="main-button size-normal">{t('button.generate')}</button>
            </div>
        </form>
    </>;
};

export default OrdersReportPage;