import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import Spinner from '../../../components/Spinner';
import {fetchTransfers} from './slice';
import {format} from '../../../services/price';
import apiClient from '../../../store/apiClient';

const TransfersPage = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const {loading, list} = useAppSelector((state) => state.adminTransfers);

    useEffect(() => {
        dispatch(fetchTransfers());
    }, []);

    const getTransferFile = async (id: number, name: string) => {
        try {
            await apiClient.getTransferFile(id, name);
        } catch (e) {

        }
    };

    return <>
        {loading && <Spinner/>}

        <div style={{padding: '10px 20px'}}>
            {/*<a>Generate transfer</a>*/}
        </div>

        <table className="table-list">
            <thead>
            <tr>
                <th>{t('transfer.id')}</th>
                <th>{t('transfer.recipients')}</th>
                <th>{t('transfer.total')}</th>
            </tr>
            </thead>
            <tbody>
            {list.map((i, k) => <tr key={k} onClick={() => getTransferFile(i.id, i.transferId)}>
                <td>{i.transferId}</td>
                <td>{i.recipients}</td>
                <td>{format(i.total)}</td>
            </tr>)}
            </tbody>
        </table>
    </>;
};

export default TransfersPage;